<template>
  <div>
    <!-- START DESKTOP NAVBAR -->
    <nav class="w-full hidden md:block">
      <div class="py-3 px-10 border-b-2 border-nColorNavbarPurple">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link
                v-if="isAuthenticated"
                :to="{
                  name: 'UserHome',
                  params: {
                    company: company,
                  },
                }"
              >
                <img
                  v-if="profile"
                  class="max-h-20"
                  :src="profile.company_logo"
                />
              </router-link>
            </div>
          </div>
          <div>
            <div class="ml-4 flex items-center md:ml-6">
              <div class="hidden md:block">
                <div
                  class="mx-10 flex items-baseline space-x-4 text-nColorBlue"
                >
                  <router-link
                    v-if="!isAuthenticated"
                    to="/login"
                    class="uppercase font-semibold"
                    >Login</router-link
                  >
                </div>
              </div>

              <!-- Profile dropdown -->
              <div class="ml-3 relative" v-click-away="onClickAway">
                <div v-if="isAuthenticated">
                  <button
                    @click="showProfileDropdown()"
                    class="max-w-xs focus:outline-none"
                    id="user-menu"
                    aria-haspopup="true"
                  >
                    <span class="sr-only">Open user menu</span>

                    <img
                      v-if="
                        (profile && profile.avatar) ||
                          (profile && profile.nha.details.profile_picture)
                      "
                      class="w-12 h-auto rounded-full"
                      :src="
                        profile.avatar || profile.nha.details.profile_picture
                      "
                      alt=""
                    />
                    <span v-else>Your Account</span>
                  </button>
                </div>
                <div
                  class="origin-top-right absolute right-0 mt-2 w-52 rounded-md shadow-lg bg-white ring-1 ring-black
                  ring-opacity-5 z-30"
                  :class="{ hidden: profileDropdown }"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div
                    class="text-center text-gray-700 py-2.5 px-2.5"
                    v-if="profile"
                  >
                    <p class="font-bold text-xl">{{ profile.user_name }}</p>
                    <p class="font-medium text-gray-500 text-sm">
                      {{ profile.email }}
                    </p>
                  </div>
                  <router-link
                    @click.prevent="
                      linkClick(
                        company,
                        profile.flags.is_candidate,
                        profile.slug
                      )
                    "
                    to=""
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    <div class="flex flex-row items-center justify-start">
                      <svg class="svg-icon w-4" viewBox="0 0 20 20">
                        <path
                          fill="#374151"
                          d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"
                        ></path>
                      </svg>
                      <p class="mt-1 pl-1.5">Your Profile</p>
                    </div>
                  </router-link>
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    @click="onSignOut()"
                  >
                    <div class="flex flex-row items-center justify-start">
                      <svg
                        version="1.1"
                        x="0px"
                        y="0px"
                        class="w-4"
                        viewBox="0 0 490.3 490.3"
                        style="enable-background:new 0 0 490.3 490.3;"
                        xml:space="preserve"
                      >
                        <g id="Sign_Out" fill="#374151">
                          <path
                            d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3
                              s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6
                              c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1
                              C27.9,58.95,0,86.75,0,121.05z"
                          />
                          <path
                            d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9
                              c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63
                              C380.6,325.15,380.6,332.95,385.4,337.65z"
                          />
                        </g>
                      </svg>
                      <p class="mt-0.5 pl-1.5">Sign out</p>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <!-- END DESKTOP NAVBAR -->
    <!-- START MOBILE NAVBAR -->
    <div
      class="md:hidden border-b-2 border-nColorNavbarPurple flex justify-between items-center"
    >
      <router-link
        v-if="isAuthenticated"
        :to="{
          name: 'UserHome',
          params: {
            company: company,
          },
        }"
      >
        <img v-if="profile" class="h-10 m-4" :src="profile.company_logo" />
      </router-link>
      <button
        class="text-white w-10 h-10 md:hidden outline-none focus:outline-none"
        @click="sideBarToggle()"
      >
        <span class="sr-only">Open menu</span>
        <div class="block w-5">
          <span
            aria-hidden="true"
            class="block absolute h-0.5 w-5 bg-nColorNavbarPurple transform transition duration-500 ease-in-out"
            :class="{
              'rotate-45': hamburgerMenuCheckbox,
              ' -translate-y-1.5': !hamburgerMenuCheckbox,
            }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute  h-0.5 w-5 bg-nColorNavbarPurple transform transition duration-500 ease-in-out"
            :class="{ 'opacity-0': hamburgerMenuCheckbox }"
          ></span>
          <span
            aria-hidden="true"
            class="block absolute  h-0.5 w-5 bg-nColorNavbarPurple transform  transition duration-500 ease-in-out"
            :class="{
              '-rotate-45': hamburgerMenuCheckbox,
              ' translate-y-1.5': !hamburgerMenuCheckbox,
            }"
          ></span>
        </div>
      </button>
    </div>
    <nav
      v-click-away="onSidebarClickAway"
      class="bg-white fixed -right-1/2 sideBarDrawer bottom-0 block md:hidden transition-all duration-700 ease-in-out w-1/2 text-white border-l-2 border-black
    z-40"
      :class="{ showSidebar: showSidebar }"
    >
      <div class="h-full md:hidden shadow-2xl">
        <div class="h-full">
          <div class="flex bg-gray-800 py-4 items-center">
            <div>
              <img
                v-if="
                  (profile && profile.avatar) ||
                    (profile && profile.nha.details.profile_picture)
                "
                class="h-8 w-8 rounded-full ml-4"
                :src="profile.avatar || profile.nha.details.profile_picture"
                alt=""
              />
            </div>
            <div class="text-left text-gray-300 pl-2" v-if="profile">
              <p class="font-bold text-lg">{{ profile.user_name }}</p>
              <p class="font-medium text-gray-500 text-xs">
                {{ profile.email }}
              </p>
            </div>
          </div>
          <router-link
            v-if="isAuthenticated"
            @click.prevent="
              linkClick(company, profile.flags.is_candidate, profile.slug)
            "
            to=""
          >
            <div
              @click="
                showSidebar = false;
                hamburgerMenuCheckbox = false;
              "
              class="py-2"
              :class="{
                'bg-white text-nColorBlue':
                  $route.name == 'Profile' || $route.name == 'EmployeeProfile',
              }"
            >
              <svg
                class="h-5 w-5 text-nColorBlue inline-block mx-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M12.075,10.812c1.358-0.853,2.242-2.507,2.242-4.037c0-2.181-1.795-4.618-4.198-4.618S5.921,4.594,5.921,6.775c0,1.53,0.884,3.185,2.242,4.037c-3.222,0.865-5.6,3.807-5.6,7.298c0,0.23,0.189,0.42,0.42,0.42h14.273c0.23,0,0.42-0.189,0.42-0.42C17.676,14.619,15.297,11.677,12.075,10.812 M6.761,6.775c0-2.162,1.773-3.778,3.358-3.778s3.359,1.616,3.359,3.778c0,2.162-1.774,3.778-3.359,3.778S6.761,8.937,6.761,6.775 M3.415,17.69c0.218-3.51,3.142-6.297,6.704-6.297c3.562,0,6.486,2.787,6.705,6.297H3.415z"
                ></path>
              </svg>
              <span class="text-sm font-semibold uppercase text-nColorBlue"
                >Profile</span
              >
            </div>
          </router-link>
          <a
            href="#"
            class="text-sm font-semibold uppercase"
            role="menuitem"
            @click="onSignOut()"
          >
            <div
              class="py-2"
              @click="
                showSidebar = false;
                hamburgerMenuCheckbox = false;
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 text-nColorRed inline-block mx-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                />
              </svg>
              <span class="text-nColorRed">Sign out</span>
            </div>
          </a>
        </div>
      </div>
    </nav>
    <!-- END MOBILE NAVBAR -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { mixin as VueClickAway } from "vue3-click-away";
import TokenService from "@/common/token.service";
import { VTooltip } from "v-tooltip";

export default {
  name: "Navbar",
  mixins: [VueClickAway],
  directives: {
    tooltip: VTooltip,
  },
  data() {
    return {
      profileDropdown: true,
      showSidebar: false,
      hamburgerMenuCheckbox: false,
      menuState: false,
      company: TokenService.getToken("company"),
    };
  },
  methods: {
    linkClick(company, isCandidate, slug) {
      this.profileDropdown = true;
      this.hamburgerMenuCheckbox = false;
      if (isCandidate) {
        this.$router.push({
          name: "Profile",
          params: { company: company },
        });
      } else {
        this.$router.push({
          name: "EmployeeProfile",
          params: { company: company, slug: slug },
        });
      }
    },
    sideBarToggle() {
      if (!this.menuState) {
        this.showSidebar = true;
        this.hamburgerMenuCheckbox = true;
      }
    },
    onSignOut() {
      this.$store
        .dispatch("signOut")
        .then(() => {
          var company = this.$store.getToken("companySlug");
          this.$store.dispatch("purgeProfileInfo");
          this.profileDropdown = true;
          this.$router.replace({
            name: "Login",
            query: { redirect: "/" + company },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onClickAway() {
      this.profileDropdown = true;
    },
    onSidebarClickAway() {
      if (this.showSidebar) {
        this.showSidebar = false;
        this.hamburgerMenuCheckbox = false;
        this.menuState = true;
      } else {
        this.menuState = false;
      }
    },
    showProfileDropdown() {
      this.profileDropdown = false;
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "user", "profile"]),
  },
  mounted() {},
};
</script>

<style scoped>
.showSidebar {
  right: 0 !important;
}
.sideBarDrawer {
  top: 4.5em;
}
</style>
