<template>
  <div
    class="footer md:py-8 flex flex-wrap justify-center md:justify-around w-full select-none"
  >
    <div
      class="container max-w-8xl py-3 mx-auto px-4 sm:px-6 lg:px-8 relative z-30"
    >
      <div
        class="footer-email md:justify-self-end w-full text-center md:w-auto md:items-center"
      >
        <img
          :src="require('@/assets/images/logo.svg')"
          alt="texperia logo"
          class="w-20 relative left-1/2 transform -translate-x-1/2 pb-4 md:pt-4"
        />
      </div>
      <div class="footer-copyright w-full text-center md:w-auto">
        <p class="text-sm md:text-md text-black">
          &copy; Texperia Techsolutions Pvt Ltd. All rights reserved.
        </p>
      </div>
      <div class="hidden md:block">&nbsp;</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped></style>
