<template>
  <div class="text-black flex flex-col h-screen">
    <Navbar />
    <div id="content" class="mx-10 flex-grow" v-if="profile">
      <div
        v-if="profile.flags.nha_complete"
        class="bg-white border border-nColorStrokeGray rounded-xl mt-8"
      >
        <ProfileComponent
          :option="profile.nha.option"
          :values="profile.nha.details"
          :defaults="profile.nha.default_animations"
        />
      </div>
      <div v-else-if="!profile.flags.is_logged_in_user_profile">
        <div
          class="md:justify-self-end w-full text-center md:w-auto md:items-center pt-24 md:pt-10"
        >
          <img
            :src="
              require('@/assets/images/profile/guest_incomplete_profile.svg')
            "
            class="w-1/3 reports-content relative left-1/2 transform -translate-x-1/2"
          />
          <p class="text-xl md:text-3xl font-semibold text-white pt-4">
            This profile is still not complete
          </p>
        </div>
      </div>
      <div class="profile-details md:pt-0 " v-else>
        <div
          class="md:justify-self-end w-full text-center md:w-auto md:items-center py-24 md:pt-10 bg-nColorFillGray border border-nColorStrokeGray rounded-xl mt-8"
        >
          <img
            :src="
              require('@/assets/images/profile/employee_incomplete_profile.svg')
            "
            class="reports-content relative left-1/2 transform -translate-x-1/2"
          />
          <p class="text-xl md:text-3xl font-semibold p-text-1 pt-4">
            To create your profile and
          </p>
          <p class="text-xl md:text-3xl font-semibold md:pt-2 pb-4 text-black">
            share it with your team
          </p>
          <router-link
            :to="{
              name: 'ExperienceDetailPage',
              params: {
                experience_link_id: profile.nha.exp_link_id,
              },
            }"
            class="bg-nColorBlue rounded-lg font-semibold text-white p-2 px-4"
          >
            Click here
          </router-link>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import Navbar from "@/components/Common/Navbar.vue";
import Footer from "@/components/Common/Footer.vue";
import ProfileComponent from "@/components/Elements/Profile/Index.vue";

export default {
  name: "Home",
  components: { Navbar, Footer, ProfileComponent },
  data() {
    return {
      slug: this.$route.params.slug,
      profile: null,
    };
  },
  created() {
    ApiService.get(apiResource.user.getProfile, {
      slug: this.$route.params.slug,
    })
      .then(({ data }) => {
        this.profile = data.data;
      })
      .catch(() => {});
  },
  mounted() {},
};
</script>

<style scoped></style>
