<template>
  <div>
    <component :is="option" :values="values" :defaults="defaults"></component>
  </div>
</template>

<script>
import Option1 from "@/components/Elements/Profile/Option1.vue";
import Option2 from "@/components/Elements/Profile/Option2.vue";

export default {
  name: "UserProfile",
  props: {
    option: {
      type: String,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
    defaults: {
      type: Object,
      required: true,
    },
  },
  components: { Option1, Option2 },
  methods: {},
};
</script>

<style scoped></style>
